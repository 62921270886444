import React, { Component, useCallback } from 'react';
import LogoView from '../../views/logo-view';

export default class LoadingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    return (
        <div id="app-container">
        <div className="loading-m">
          <div className="loader">
            <div className="inner one"></div>
            <div className="inner two"></div>
            <div className="inner three"></div>
          </div>
        </div>
        <LogoView />
      </div>
    )
  }
}
