import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';
import libConfig from '../../../lib/config';

import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Alert} from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import { LoadSpinner } from '../../../widgets';
import { ListPackageComponent } from '../../../component';
import TextArea from 'antd/lib/input/TextArea';

export default class ScanFanpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            totalData: 0
        };
    }
    componentWillMount = async () => {

    }
    onCheck = async (values) => {
        try {
            this.setState({ loading: true, listData: [] });
            console.log('Values form', values);

        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    message: 'Quét fanpage',
                    description: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error'
                })
            });
        }
    }
    render() {
        return (
            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Quét fanpage</h3>
                            </div>
                            <div className="block-content">
                                <div className="row justify-content-center">
                                    <div className="col-md-5">
                                        <Form
                                            onFinish={this.onCheck}
                                            ref={(evt) => this.form = evt}
                                        >
                                            <Form.Item
                                                name="token"
                                                rules={[{ required: true, message: 'Bạn phải nhập token!' }]}
                                                label="Nhập token"
                                            >
                                                <Input  />
                                            </Form.Item>
                                            <Form.Item
                                                name="keyword"
                                                label={`Từ khóa tìm kiếm`}
                                                rules={[{ required: true, message: 'Bạn phải từ khóa!' }]}
                                            >
                                                <Input  />
                                            </Form.Item>
                                            <div className="mb-20">
                                                <TextArea style={{minHeight: "200px"}}></TextArea>
                                            </div>
                                            <div className="text-center">
                                                <Button type="primary" className="mr-3" loading={this.state.loading} htmlType="submit" style={{width: "150px"}}>
                                                    Quét
                                                </Button>
                                                <Button type={"ghost"} htmlType="submit" style={{width: "150px"}} disabled={!this.state.loading} onClick={() => this.setState({ loading: false, run: false})}>
                                                    Dừng
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
