import React, { Component } from 'react';
import {AccountMetaData, getCookie, setCookie, checkIssetLogin} from '../../config/app';

import { fetchData } from '../../lib/apis';

export default class Login extends Component{
  constructor(props){
    super(props);
  }
  componentWillMount = async () => {
    try{
      let params = new URLSearchParams(this.props.location.search);
      if(!params.get('error') && this.props.location.hash.indexOf('#') > -1){
        let result = new URLSearchParams(this.props.location.hash.replace('#', '?'));
        let param_state = new URLSearchParams(decodeURIComponent(result.get("state")));
        let response = await fetchData({
          url: 'api/v2/login',
          method: 'post',
          data: {
            access_token: result.get('access_token'),
            data_access_expiration_time: result.get('data_access_expiration_time'),
            expires_in: result.get('expires_in'),
            ref: getCookie('ref')
          }
        })
        let user = response.user;

        setCookie('token', response.token, 7);
        setCookie('auth.token.facebook', response.token_facebook, 7);
        setCookie('usr', encodeURIComponent(JSON.stringify(user)), 7);

        if(param_state.get('redirect_url')){
          window.location.href = param_state.get('redirect_url')
        } else {
          this.props.history.push('/accounts');
        }
      } else{
        if(AccountMetaData.checkIssetLogin()){
          if(params.get('redirect_url')){
            window.location.href = params.get('redirect_url')
          } else {
            this.props.history.push('/accounts');
          }
        } else {
          this.props.notification({
            title: 'Đăng nhập',
            content: 'Đăng nhập thất bại',
            type: "error"
          });
          AccountMetaData.logIn({
            state: `redirect_url=${params.get('redirect_url')}`
          });
        }
      }
    } catch(err){
      setCookie('token', '', 0);
      setCookie('usr', '', 0);
      setCookie('auth.token.facebook', '', 0);
      this.props.notification({
        title: 'Đăng nhập',
        content: err.message || 'Đăng nhập thất bại',
        type: "error"
      });
    }
  }
  componentDidMount = () => {
    // if(checkIssetLogin()){
    //   this.props.history.push('/');
    // } else{
    //     AccountMetaData.logIn();
    // }
  }
  render(){
    return(
        <div id="main-container" className="login-page" style={{height: "100%", position: "fixed", top: 0, left: 0, right: 0, bottom: 0, display: "flex"}}>
          <div style={{margin: 'auto', textAlign: "center"}}>
            <span className="loader-m" style={{width: "40px", height: "40px"}}></span>
            <h2>loading...</h2>
          </div>
        </div>
    )
  }
}
