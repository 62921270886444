import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

export default class listPackageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {

    }
    changeValue = (e, key) => {
        let e_package = this.props.listPackageSystems[key];
        let value = e.target.value;
        if (e.target.name == 'checkbox') {
            e_package['checked'] = e.target.checked;
        } else if (e.target.name == 'number_page') {
            if (parseInt(e.target.value) < 1) {
                e_package['number_page'] = 1;
            } else {
                e_package['number_page'] = value;
            }
        } else {
            e_package[e.target.name] = value;
        }

        this.setState({
            reload: !this.state.reload
        })
    }
    focusOut = (e, key) => {
        let e_package = this.props.listPackageSystems[key];
        let value = e.target.value ? parseInt(e.target.value) : 0;
        let min = parseInt(e.target.getAttribute('min'));
        if (value < min) {
            e.target.value = min;
            e_package[e.target.name] = e.target.value;
            this.setState({
                reload: !this.state.reload
            })
        }
    }
    render() {
        let listHtmlPackages = [];
        let listHtmlPackagesMobile = [];
        let total_payment = 0;

        console.log("this.props.listPackageSystems", this.props.listPackageSystems);

        this.props.listPackageSystems.map((item, i) => {
            try{
                let amount = 0;
                let discount = 0;

                if (item.package_type == 1) {
                    amount = item.selected_month * item.amount;
                } else if (item.package_type == 2) {
                    amount = (item.number_page * item.amount) * item.selected_month;
                    if (parseInt(item.package_vip) == 2) {
                        amount = item.amount_package_vip * item.selected_month;
                    }
                } else if (item.package_type == 3) {
                    amount = item.num_message * item.amount;
                } else if (item.package_type == 4) {
                    amount = item.selected_month * item.amount;
                } else {
                    return false;
                }
                let amount_before_discount = amount;
                if(amount && item.number_month){
                    let index_month = item.number_month.indexOf(parseFloat(item.selected_month));
                    if (item.discount && item.discount[index_month]) {
                        discount = item.discount[index_month];
                        amount = amount - ((amount / 100) * discount);
                    }
                }

                item['total_amount'] = amount;
                let description = (<div>
                    <p className="font-w600 mb-10">{item.title}</p>
                    {
                        item.desc ?
                            item.desc.map((desc, ii) => {
                                return <p key={ii} className="mb-0 text-danger">{desc}</p>
                            })
                            : null
                    }
                </div>);
                let option_number_page = (
                    <div>
                        {
                            item.name == 'spam_profile' ?
                                <div className="d-flex">
                                    <select className="form-control mr-10" name="package_vip" onChange={(e) => this.changeValue(e, i)} style={{ width: "auto" }} value={item.package_vip}>
                                        <option value={1}>Gói thường</option>
                                        <option value={2}>Gói vip</option>
                                    </select>
                                    {
                                        parseInt(item.package_vip) == 1 ?
                                            <input onBlur={(e) => this.focusOut(e, i)} type="number" className="form-control" name="number_page" style={{ width: "100px" }} value={item.number_page} min={1} onChange={(e) => this.changeValue(e, i)} />
                                            : <input disabled type="number" className="form-control" name="number_page" style={{ width: "100px" }} value={item.number_page} min={1} onChange={(e) => this.changeValue(e, i)} />
                                    }
                                </div>
                                : 'Tất cả'
                        }
                    </div>
                )
                let option_number_month = (
                    <div>
                        {
                            item.number_month ?
                                <select className="form-control" onChange={(e) => this.changeValue(e, i)} name="selected_month" value={item.selected_month}>
                                    {
                                        item.number_month.map((month) => {
                                            return <option value={month} key={i + '_' + month}>{month < 1 ? `${Math.floor(month*30)} ngày` : `${month} tháng`}</option>
                                        })
                                    }
                                </select>
                                : item.selected_month ? <p style={{ padding: "0px 0px" }} className="mb-0">{item.selected_month < 1 ? `${item.selected_month*30} Ngày` : `${item.selected_month} Tháng`}</p> : ''
                        }
                    </div>
                )
                let option_number_message = (<div>
                    {
                        item.num_message >= 0 ?
                            <input onBlur={(e) => this.focusOut(e, i)} type="number" className="form-control" name="num_message" onChange={(e) => this.changeValue(e, i)} style={{ width: "100px", margin: "auto" }} value={item.num_message} min={parseInt(item.min_message)} /> : "Không giới hạn"
                    }
                </div>)
                let item_payment = (<div style={{position: "relative"}}>
                    {this.props.service.show_discount && discount && amount_before_discount ? <span className="amount-before-discount"><NumberFormat value={amount_before_discount} displayType={'text'} thousandSeparator={true} />đ</span> : null}
                    <span className="amount-after-discount"><NumberFormat value={item['total_amount']} displayType={'text'} thousandSeparator={true} />đ </span>
                    {this.props.service.show_discount && discount ? <div className="discount-label"> <span>-{discount}%</span> </div> : null}
                    </div>);

                listHtmlPackages.push(<tr key={item.name} className={!item.checked ? 'disabled' : ''} data-key={i}>
                    <td className="text-left">
                        <label className="css-control css-control-primary css-checkbox">
                            <input type="checkbox" className="css-control-input" name="checkbox" onChange={(e) => this.changeValue(e, i)} checked={item.checked} />
                            <span className="css-control-indicator"></span>
                        </label>
                    </td>
                    <td>{description}</td>
                    <td>{option_number_page}</td>
                    <td>{option_number_month}</td>
                    <td className="text-center">{option_number_message}</td>
                    <td className="text-right pr-3">{item_payment}</td>
                </tr>)

                listHtmlPackagesMobile.push(
                    <tr key={item.name} className={!item.checked ? 'disabled' : ''} data-key={i}>
                        <td className="text-left" colSpan={5}>
                            <div className="d-flex">
                                <label className="css-control css-control-primary css-checkbox p-0">
                                    <input type="checkbox" className="css-control-input" name="checkbox" onChange={(e) => this.changeValue(e, i)} checked={item.checked} />
                                    <span className="css-control-indicator"></span>
                                </label>
                                <div className="ml-15">
                                    <div className="pb-5 border-bottom mb-10">
                                        {description}
                                    </div>
                                    <div className="d-flex pb-5 justify-content-between">
                                        <label className="mr-5">Số page/ profile:</label>
                                        {option_number_page}
                                    </div>
                                    <div className="d-flex pb-5 justify-content-between">
                                        <label className="mr-5">Thời gian:</label>
                                        {option_number_month}
                                    </div>
                                    <div className="d-flex pb-5 justify-content-between">
                                        <label className="mr-5">Số lượng gửi:</label>
                                        {option_number_message}
                                    </div>
                                    <div className="d-flex pb-5 justify-content-between">
                                        <label className="mr-5">Thành tiền: </label>
                                        {item_payment}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )

                if (item.checked) {
                    total_payment += item.total_amount;
                }
            } catch(err){
                console.log(err);
                return null;
            }
        })

        return (
            <div>
                <div className="table-responsive d-none d-md-block">
                    <table className="table table-striped table-vcenter table-hover table-package">
                        <thead>
                            <tr>
                                <th className="text-left" style={{ width: "100px" }}>Chọn</th>
                                <th>Loại gói</th>
                                <th>Số page / profile</th>
                                <th>Thời gian</th>
                                <th className="text-center">Số lượng gửi</th>
                                <th className="text-right pr-3">Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listHtmlPackages}
                            <tr>
                                <td colSpan={4} className="text-right">
                                    <h5 className="m-0"><strong>Tổng tiền</strong></h5>
                                </td>
                                <td colSpan={2} className="text-right pr-3" style={{ minWidth: "160px" }}>
                                    <h5 className="m-0"><strong>{<NumberFormat value={total_payment} displayType={'text'} thousandSeparator={true} />} vnđ</strong></h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive d-md-none">
                    <table className="table table-striped table-vcenter table-hover table-package-mb">
                        <tbody>
                            {listHtmlPackagesMobile}
                            <tr>
                                <td className="text-right">
                                    <h5 className="m-0"><strong>Tổng tiền</strong></h5>
                                </td>
                                <td className="text-right pr-3" style={{ minWidth: "160px" }}>
                                    <h5 className="m-0"><strong>{<NumberFormat value={total_payment} displayType={'text'} thousandSeparator={true} />} vnđ</strong></h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
