import React, { Component } from 'react';

export default class TermUsePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentWillMount = async () => {

    }
    componentDidMount = async () => {
        let hash = this.props.location.hash;
        if (hash) {
            if (document.querySelector(hash)) {
                document.querySelector(hash).scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
    render() {
        return (
            <div className="content guides">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded" style={{ height: "100%" }}>
                            <div className="block-content pb-20">
                                <div className="row">
                                    <div className="col-12 col-md-8">
                                        <div className="mb-20">
                                            <h4 className="block-title">Điều Khoản Sử Dụng Phần Mềm Retarget Facebook</h4>
                                        </div>
                                        <div>
                                            Chấp Thuận: Người dùng cam kết rằng họ đã<ol><li><p><strong>Mục Đích Sử Dụng</strong>: Phần mềm remarketing Facebook được thiết kế để tự động gửi tin nhắn đến khách hàng cũ trên fanpage, nhằm tăng cường tương tác và cải thiện trải nghiệm khách hàng.</p></li><li><p><strong>Chấp Thuận</strong>: Người dùng cam kết rằng họ đã nhận được sự đồng ý từ khách hàng trước khi sử dụng phần mềm để gửi tin nhắn. Việc gửi tin nhắn mà không có sự đồng ý của khách hàng có thể vi phạm quy định của Facebook và pháp luật hiện hành.</p></li><li><p><strong>Quyền Riêng Tư</strong>: Người dùng phải tuân thủ chính sách bảo mật và quyền riêng tư của khách hàng. Không được lưu trữ hoặc sử dụng thông tin cá nhân của khách hàng cho mục đích khác ngoài việc gửi tin nhắn remarketing.</p></li><li><p><strong>Trách Nhiệm</strong>: Người dùng hoàn toàn chịu trách nhiệm về nội dung tin nhắn và cách thức sử dụng phần mềm. Chúng tôi không chịu trách nhiệm về bất kỳ khiếu nại nào liên quan đến việc sử dụng phần mềm này.</p></li><li><p><strong>Ngừng Sử Dụng</strong>: Chúng tôi có quyền ngừng cung cấp dịch vụ nếu phát hiện người dùng vi phạm các điều khoản sử dụng này hoặc các quy định của Facebook.</p></li></ol>
                                            <p>Bằng việc sử dụng phần mềm này, bạn đồng ý với các điều khoản trên.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row justify-content-center">
                                    <div className="col-md-10 col-sm-12">
                                    <iframe style={{width: "100%", minHeight: "700px"}} src="https://www.youtube.com/embed/6uJ00cpNU2Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>    
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
