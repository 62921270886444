import React, { Component } from 'react';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Table } from 'antd';
import qs from "qs";

export default class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            totalData: 0,
            services: []
        };
        this.page = 1;
        this.limit = 20;
        this.formFilter = null
    }
    componentWillMount = async () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                limit: this.limit,
                page: this.page,

            }

            if(this.formFilter) params = {...params,...this.formFilter.getFieldsValue()}

            let res = await fetchData({
                url: `api/v2/ow/user/list2?${qs.stringify(params)}`
            });

            this.setState({ listData: res.data, loading: false, totalData: res.total, services: res.services });
        } catch (err) {
            this.setState({ loading: false });
        }
    }
    updateTransaction = async (values) => {
        this.setState({ loadingForm: true });
        try {
            let res = await fetchData({
                url: `api/v2/ow/transaction/${values._id}`,
                method: 'post',
                data: values
            });
            this.setState({ visibleForm: false });
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công!',
                title: 'Xác nhận thanh toán',
                type: 'success'
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xác nhận thanh toán',
                type: 'error'
            })
        }
        this.setState({ loadingForm: false });
    }
    render() {
        return (
            <div className="content">
                <Modal
                    title="Cập nhập người dùng"
                    footer={null}
                    onCancel={() => this.setState({ visibleForm: false })}
                    visible={this.state.visibleForm}
                    centered
                >
                    <Form
                        onFinish={this.updateTransaction}
                        ref={(evt) => this.form = evt }
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="note" label="Ghi chú">
                            <Input.TextArea style={{minHeight: "150px"}}/>
                        </Form.Item>
                        <div className="d-flex justify-content-end">
                            <Button danger onClick={() => this.setState({ visibleForm: false })} disabled={this.state.loadingForm}>Đóng</Button>
                            <Button htmlType="submit" type="primary" className="ml-2" loading={this.state.loadingForm}>Xác nhận</Button>
                        </div>
                    </Form>
                </Modal>
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Quản lý người dùng</h3>
                            </div>
                            <div className="block-header pt-0 pb-0">
                                <div className="d-flex mb-10">
                                    <button className={`btn btn-primary btn-sm d-flex`}>
                                        Tất cả: {this.state.totalData}
                                    </button>
                                    <Tooltip title="Làm mới">
                                        <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={this.getListData}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="block-header">
                                <Form
                                    layout="inline"
                                    className="w-100"
                                    ref={(evt) => this.formFilter = evt}
                                    onFinish={this.getListData}
                                >
                                    <div className='row w-100 '>
                                        <div className="col-md-3" >
                                            <Form.Item name={"keyword"} >
                                                <Input placeholder="Tìm kiếm.." 

                                                />
                                            </Form.Item>
                                        </div>
                                        <div className='col-auto'>
                                            <Button htmlType="submit">Lọc</Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div className="block-content">
                                <Table 
                                    dataSource={this.state.listData}
                                    loading={this.state.loading}
                                    columns={[
                                        {
                                            title: "Id",
                                            dataIndex: "_id"
                                        },
                                        {
                                            title: "Avatar",
                                            dataIndex: "picture",
                                            render: (value, record) => {
                                                return <>
                                                    <img 
                                                        src={value}
                                                        width={40}
                                                        className="rounded"
                                                    /> 
                                                </>
                                            }
                                        },
                                        {
                                            title: "Name",
                                            dataIndex: "display_name"
                                        },
                                        {
                                            title: "Email",
                                            dataIndex: "email"
                                        },
                                        {
                                            title: "Ngày tham gia",
                                            dataIndex: "time_created",
                                            render: (item) => item && moment(item).format("HH:mm DD/MM/YYYY")
                                        }
                                    ]}
                                    pagination={{
                                        total: this.state.totalData,
                                        pageSize: this.limit,
                                        current: this.page,
                                        onChange: (page, pageSize) => {
                                            this.page = page;
                                            this.limit = pageSize;
                                            this.getListData();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
