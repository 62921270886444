import React, { Component } from 'react';

export default class LogoView extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div id="dummy-view" className="logo-view">
        <div>
          <a>
            <img id="logo" alt="logo" src={require("../img/logo1.png")} />
            {/* <h2>Vnp SoftWare</h2> */}
          </a>
          <p style={{ marginTop: "10px" }}>Bản quyền thuộc VnpSoftware</p>
        </div>
      </div>
    )
  }
}
