import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// import './css/main.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import 'antd/dist/antd.css'; //theme antd
import './css/fontawesome.min.css';
import './codebase/css/codebase.min.css';
import './codebase/css/style.css';

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
     document.getElementById('root-chat')
   );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
