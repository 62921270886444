import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import { LoadSpinner } from '../../../widgets';

export default class Guides extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentWillMount = async () => {

    }
    componentDidMount = async () => {
        let hash = this.props.location.hash;
        if(hash){
            if (document.querySelector(hash)) {
                document.querySelector(hash).scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
    render() {
        return (
            <div className="content guides">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded" style={{ height: "100%" }}>
                            <div className="block-content pb-20">
                                <div className="row">
                                    <div className="col-12 col-md-8">
                                        <div className="border-bottom mb-20 pb-30">
                                            <h3 className="block-title mb-10">Gửi tin nhắn hàng loạt</h3>
                                            <p>Là công cụ giúp bạn Gửi tin nhắn hàng loạt cho khách hàng đã từng tương tác qua fanpage, nick cá nhân, hoặc tệp uid khách hàng chưa từng tương tác.</p>
                                        </div>
                                        <div className="mb-20">
                                            <p className="mb-10">Giới thiệu tính năng:</p>
                                            <ul>
                                                <li>Re-inbox đến tất cả khách hàng trong fanpage</li>
                                                <li>Gửi tin nhắn đến tất cả uid mục tiêu</li>
                                                <li>Chi phí sử dụng hợp lý, giao diện dễ sử dụng</li>
                                            </ul>
                                            {/* <div className="video">
                                                <iframe src="https://www.youtube.com/embed/6uJ00cpNU2Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div> */}
                                        </div>
                                        <div className="mb-20">
                                            <h4 className="block-title">Hướng dẫn cài đặt Spam Tin nhắn hàng loạt</h4>
                                        </div>
                                        <div>
                                            <p className="mb-20"><b>Bước 1:</b> Tải và cài đặt tiện ích mở rộng</p>
                                            <p className="mb-20">Tại giao diện menu bấm vào tải tiện ích</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/1.png')} /></p>
                                            <p className="mb-20">Lưu lại và tiến hành giải nén:</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/2.png')} /></p>
                                            <p className="mb-20">Giải nén xong thì cài đặt tiện ích:</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/3.png')} /></p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/16.png')} /></p>
                                            <p className="mb-20">Lựa chọn thư mục đã giải nén:</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/15.png')} /></p>
                                            <p className="mb-20"><b>Bước 2:</b> : Sau khi cài đặt extention phần mềm vào trình duyệt reload lại trang, bạn chọn “sử dụng ngay”:</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/5.png')} /></p>
                                            <p className="mb-20"><b>Bước 3:</b> Tùy vào gói cước bạn đang sử dụng, chọn 1 chức năng spam cần sử dụng, cách sử dụng là tương tự nhau. Ở bài viết này, mình sẽ thao tác trên chức năng spam fanpage (gửi tin đến toàn bộ khách hàng cũ từng ib page) nhé!</p>
                                            <p className="mb-20">Chọn fanpage cần spam, sau đó nhấn “click lấy khách”:</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/6.png')} /></p>
                                            <p className="mb-20">Bạn cũng có thể lọc ra những khách hàng tùy theo yêu cầu như chỉ lọc ra khách có SĐT/không có SĐT, khoảng thời gian khách nhắn tin gần đây nhất hay thậm chí là tin nhắn của khách chứa từ khóa mà bạn nhập vào:</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/7.png')} /></p>
                                            <p className="mb-20">Sau khi click lấy khách, danh sách khách hàng sẽ hiện ra như hình, chọn “tiếp tục” để bắt đầu quá trình soạn và cấu hình nhắn tin: </p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/8.png')} /></p>
                                            <p className="mb-20"><b>Bước 4:</b> Lên kịch bản re-marketing</p>
                                            <p className="mb-20">Sau khi chọn “Tiếp tục”, giao diện cài đặt tin nhắn hiển thị như hình:</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/9.png')} /></p>
                                            <p className="mb-20">Nội dung tin nhắn:</p>
                                            <p className="mb-20">
                                                {`#{full_name}`}: hệ thống sẽ gọi tên facebook khách hàng <br></br>
                                                {`#{ Hello | Xin chao }`}: hệ thống sẽ gọi ra ngẫu nhiên 1 từ khóa có trong cụm {`#{ }`}, các từ khóa ngăn cách nhau bởi dấu |<br></br>
                                                Ví dụ: tin nhắn của bạn là: chào {`#{full_name}`} chúc bạn sinh nhật vui vẻ!<br></br>
                                                Lúc này hệ thống sẽ tự động gọi tên facebook của khách hàng thế vào đúng vị trí cụm {`#{full_name}`}.<br></br>
                                                Đặc biệt, để tránh bị facebook đánh dấu spam do trùng lặp tin nhắn, chúng tôi khuyến khích bạn sử dụng nhiều từ khóa đồng nghĩa và để vào cụm {`#{ }`}, hệ thống sẽ tự động tạo ra nhiều phiên bản tin nhắn khác nhau mà ý nghĩa vẫn không bị thay đổi. <br></br>
                                                Ví dụ: chữ “xin chào” đầu câu bạn có thể thêm nhiều từ khóa tương tự như “hello, hi, chào anh”:<br></br>
                                                {`#{xin chào| hi| hello| chào anh} #{full_name}`}, chúc bạn sinh nhật vui vẻ!<br></br>
                                                Phần mềm sẽ tự động điều chỉnh và tạo ra nhiều phiên bản tin nhắn khác nhau.
                                            </p>
                                            <p className="mb-20">
                                                Lưu ý: 
                                                Không để trực tiếp link web, mà hãy che link lại, ví dụ: 24h*com*vn,…
                                                Sđt k được để trực tiếp, mà hãy để khác biệt đi, ví dụ: 0387,893,890 ; 0387.89.38.90
                                            </p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/10.png')} /></p>
                                            <p className="mb-20">Chọn file đính kèm gửi cùng tin nhắn</p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/11.png')} /></p>
                                            <p className="mb-20">Nhấn “chọn tệp” để lấy tệp tù máy tính gửi kèm tin nhắn đến khách hàng, bạn có thể gửi kèm ảnh, thông báo… Nếu chọn nhiều file hệ thống sẽ lấy ngẫu nhiên 1 file trong danh sách tệp để gửi cho khách hàng. </p>
                                            <p className="mb-20">Cấu hình gửi tin </p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/12.png')} /></p>
                                            <p className="mb-20">Đây là phần rất quan trọng, và là 1 trong những tính năng ưu việt mà phần mềm sở hữu, bạn có thể tùy chỉnh được cấu hình gửi tin, nghĩa là điều chỉnh được tốc độ gửi tin và quãng nghỉ khi gửi tin nhằm tránh bị facebook đánh dấu spam và khóa tài khoản.</p>
                                            <p className="mb-20">Các thông số nên được cài đặt như hình:</p>
                                            <p className="mb-20">
                                                Tốc độ gửi tin: 10 khách / 1 lần gửi<br></br>
                                                Sau khi gửi được 10 tin thì dừng nghỉ 1s
                                            </p>
                                            <p className="mb-20">Việc cài đặt như vậy giúp tăng tính an toàn lên rất nhiều khi sử dụng tính năng spam đến khách hàng.</p>
                                            <p className="mb-20">Sau khi hoàn tất cài đặt, nhấn “Gửi tin nhắn”: </p>
                                            <p className="text-center mb-50"><img src={require('../../../img/guides/13.png')} /></p>
                                            <p className="mb-20">Sau khi nhấn gửi, hệ thống sẽ tự động gửi tin đến toàn bộ khách hàng đã chọn cho đến khi hoàn tất việc gửi tin nhắn. </p>
                                            <p className="mb-20">Măc dù Hệ thống spam rất an toàn nếu bạn làm đúng cách, nhưng để chiến dịch re-marketing hiệu quả, bạn không nên làm dụng gửi quá nhiều tin nhắn spam. Điều này sẽ gây ấn tượng xấu cho khách hàng. Vậy nên hãy tận dụng chức năng spam tin nhắn của phần mềm một cách thật thông minh bạn nhé! </p>
                                            
                                            <p>Chúc các bạn thành công !</p>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row justify-content-center">
                                    <div className="col-md-10 col-sm-12">
                                    <iframe style={{width: "100%", minHeight: "700px"}} src="https://www.youtube.com/embed/6uJ00cpNU2Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>    
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
