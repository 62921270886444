const settings = {}

export var dimens = {
    deviceWidth: window.innerWidth,
    domainSave: domainSaveCookie(),
    domainCookie: domainSaveCookie() != 'localhost' ? '.'+domainSaveCookie() : domainSaveCookie(),
    protocol: window.location.protocol+'//',
    full_domain: window.location.protocol+'//'+domainSaveCookie()
}

export const AccountMetaData = {
    token: (getCookie("token")) ? getToken(getCookie("token")) : '',
    logOut : () => {
        setCookie('token', '', 0);
        setCookie('usr', '', 0);
        setCookie('auth.token.facebook', '', 0);
        window.location.href = dimens.full_domain;
        // window.location.href = `${process.env.REACT_APP_LOGIN}/logout?urlRedirect=${window.location.origin}/login`;
        return true;
    },
    logIn: (option = {}) => {
        let { state } = option;
        window.location.href = `https://www.facebook.com/v8.0/dialog/oauth?client_id=${process.env.REACT_APP_FB_ID}&redirect_uri=${window.location.origin+'/login'}&state=${state}&scope=public_profile,email,pages_messaging,pages_manage_engagement,pages_read_user_content,pages_read_engagement,pages_manage_metadata&response_type=token`;
    },
    getToken: () => {
        try {
            return getCookie("token");
        } catch {
            return '';
        }
    },
    getCompany: () => {
        try {
            let company = getCookie("c_t");
            return company ? JSON.parse(company) : '';
        } catch {
            return '';
        }
    },
    setCookie: (name, value, days) => {
        var expires = "";
        if (days) {
        var date = new Date();
            date.setMonth(date.getMonth() + 1);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.domainCookie+"; path=/";
    },
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    checkIssetLogin: () => {
        if( !getCookie('token')  ) {
            return false;
        }
        try{
            dimens['token'] = getCookie("token");
            dimens['user'] = JSON.parse(decodeURIComponent(getCookie("usr")));
            dimens['auth.token.facebook']= 'Bearer '+getCookie("auth.token.facebook");
        } catch(err){
            return false;
        }
        return true;
    }
}

export const formatNumber = (nStr) => {
    nStr+='';let x=nStr.split(',');let x1=x[0];let x2=x.length>1?','+x[1]:'';let rgx=/(\d+)(\d{3})/;while(rgx.test(x1)){x1=x1.replace(rgx,'$1'+'.' + '$2');}return x1+x2;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
        date.setMonth(date.getMonth() + 1);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.domainCookie+"; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function checkIssetLogin(){
  if( !getCookie('token')  ) {
      return false;
  }
  try{
    dimens['token'] = atob(getCookie("token"));
  } catch(err){
      return false
  }
  return true;
}
export function getToken(cookie){
    try {
        return atob(cookie);
    } catch {
        return '';
    }
}
export function getPicture(params) {
    var { token, id, width, height} = params;
    width = width ? width : 80;
    height = height ? height : 80;

    return `https://graph.facebook.com/${id}/picture?height=${height}&width=${width}&access_token=${token}`
}

export function domainSaveCookie(){
    try{
        let domain = "";
        let hostname = window.location.hostname;
        if(hostname.indexOf('.') > -1){
            let arr = hostname.split('.');
            domain = arr.splice(arr.length - 2, arr.length);
            domain = domain.join('.');
        } else {
            domain =  window.location.hostname;
        }
        console.log(hostname, domain);
        return domain;
    } catch(err){
        return "";
    }
}

export function versionCompare(v1, v2, options) {
    var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split('.'),
        v2parts = v2.split('.');
 
    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }
 
    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }
 
    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }
 
    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }
 
    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }
 
        if (v1parts[i] == v2parts[i]) {
            continue;
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }
 
    if (v1parts.length != v2parts.length) {
        return -1;
    }
 
    return 0;
}

export default settings