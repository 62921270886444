import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';
import {
    Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip,
    Alert
} from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, AudioOutlined } from '@ant-design/icons';
import { LoadSpinner } from '../../../widgets';
import Chart from 'chart.js';
import locale from 'antd/es/date-picker/locale/vi_VN';
import libConfig from '../../../lib/config';
import { dimens } from '../../../config/app';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default class PLan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referral: { data: [], total: 0 },
            affiliate: null,
            amountWithDraws: '1,000,000',
            statistic: {},
            get_affiliate: false
        };
        this.page = 1;
        this.limit = 10;
        this.filter_statistic = {
            end: Date.now(),
            start: moment(Date.now()).subtract(30, "days").startOf('day').unix() * 1000
        }
    }
    componentWillMount = async () => {
        this.getAffilate();
    }
    getlistReferral = async (type) => {
        try {
            let query = `limit=${this.limit}&page=${this.page}`;
            let res = await fetchData({
                url: `api/v1/affiliate/user/referral?${query}`
            });

            this.setState({ referral: res });
        } catch (err) {

        }
    }

    getAffilate = async (type) => {
        try {
            let res = await fetchData({
                url: `api/v1/affiliate/get`
            });
            if (res.affiliate && res.affiliate.status == 1) {
                this.getStatistic();
                this.getlistReferral();
            }
            this.setState({ affiliate: res.affiliate, get_affiliate: true });
        } catch (err) {

        }
    }
    getStatistic = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/affiliate/statistic?start=${this.filter_statistic.start}&end=${this.filter_statistic.end}`
            });
            this.setState({
                statistic: res
            }, () => {
                if (res.statistic) this.showChart(res.statistic);
            })
        } catch (err) {

        }
    }
    showChart = (options) => {
        var config = {
            type: 'line',
            data: {
                labels: options.label,
                datasets: [{
                    label: 'Người dùng đăng ký',
                    fill: false,
                    backgroundColor: '#f29e4f',
                    borderColor: '#f29e4f',
                    data: options.user
                }, {
                    label: 'Người dùng giao dịch',
                    fill: false,
                    backgroundColor: '#4080ff',
                    borderColor: '#4080ff',
                    data: options.transaction
                }]
            },
            options: {
                responsive: true,
                title: {
                    display: false,
                    text: 'Chart with Multiline Labels'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0
                        }
                    }]
                }
            }
        };
        if (!window.myLine) {
            var ctx = document.getElementById('myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        } else {
            document.getElementById('myChart').remove();
            document.getElementById('block-myChart').innerHTML = '<canvas id="myChart" width="400" height="120"></canvas>';
            var ctx = document.getElementById('myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        }

    }
    copyLinkRef = (e) => {
        let copyText = document.querySelector('#link-ref');
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy")
        this.props.notification({
            content: 'Sao chép mã thành công',
            title: 'Sao chép',
            type: 'success'
        })
    }
    withdraws = () => {
        if (this.state.affiliate.wallet < 1000000) {
            this.props.notification({
                content: 'Ví affiliate của bạn phải lớn hơn 1.000.000 đồng',
                title: 'Bạn không đủ điều kiện rút tiền',
                type: 'warning'
            })
            return
        }
        this.setState({
            visibleFormWithDraw: true
        })
    }
    submitWithDraw = async () => {
        const values = await this.formH.validateFields();
        this.setState({
            confirmLoading: true
        })
        try {
            let res = await fetchData({
                url: `api/v1/affiliate/withdraws`,
                method: 'post',
                data: values
            });
            this.props.notification({
                content: 'Thao tác thành công! vui lòng chờ xác nhận từ chúng tôi',
                title: 'Yêu cầu rút tiền',
                type: 'success'
            })
            this.formH.resetFields();
            this.setState({
                confirmLoading: false,
                visibleFormWithDraw: false
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Yêu cầu rút tiền',
                type: 'error'
            })
            this.setState({
                confirmLoading: false
            })
        }
    }
    handleCancelWithDraw = () => {
        this.setState({
            visibleFormWithDraw: false
        })
    }
    handleCancelFormRegister = () => {
        this.setState({
            visibleFormRegister: false
        })
    }
    submitRegister = async () => {
        const values = await this.formRegister.validateFields();
        this.setState({
            confirmLoading: true
        })
        try {
            let res = await fetchData({
                url: `api/v1/affiliate/register`,
                method: 'post',
                data: values
            });
            this.props.notification({
                content: 'Thao tác thành công! vui lòng chờ xác nhận từ chúng tôi',
                title: 'Đăng ký tài khoản affiliate',
                type: 'success'
            })
            this.getAffilate();
            this.formRegister.resetFields();
            this.setState({
                confirmLoading: false,
                visibleFormRegister: false
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Đăng ký tài khoản affiliate',
                type: 'error'
            })
            this.setState({
                confirmLoading: false
            })
        }
    }
    render() {
        if (!this.state.get_affiliate) return null
        let alert = null;
        let info_level_affilate = (
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="block block-rounded" style={{ height: "100%" }}>
                        <div className="block-content">
                            <h4 className="">Chính sách Affiliate </h4>
                            <div className="table-responsive">
                                <table className="table table-bordered table-vcenter">
                                    <thead>
                                        <tr>
                                            <th>Level</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            libConfig.listLevelAffiliate.map((item, i) => {
                                                return <tr key={"level_affi_" + i}>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <ul className="list-function">
                                                            {
                                                                item.value.map((value, i) => {
                                                                    return <li key={"value_affi" + i}>{value}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="block block-rounded" style={{ height: "100%" }}>
                        <div className="block-content">
                            <h3>Điều kiện hợp lệ nhận hoa hồng Affiliate</h3>
                            <ul className="list-select-menu">
                                <li><i className="si si-check"></i> Các Affiliater tuyệt đối tuân thủ luật quảng cáo online theo điều luật của nước Việt Nam</li>
                                <li><i className="si si-check"></i> Tuân thủ điều khoản đối tác của chúng tôi</li>
                                <li><i className="si si-check"></i> Tài khoản của bạn cần có ít nhất 1 triệu đồng mới đủ điều kiện rút tiền</li>
                                <li><i className="si si-check"></i> Thành viên vi phạm sẽ bị hủy toàn bộ hoa hồng</li>
                                <li><i className="si si-check"></i> Tuân thủ đúng nghĩa vụ thuế và các nghĩa vụ pháp lý</li>
                                <li><i className="si si-check"></i> Liên hệ với fanpage khi đủ điều kiện rút hoặc có câu hỏi cần giải đáp</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
        if (!this.state.affiliate) {
            return (
                <div className="content">
                    <Modal
                        title={'Đăng ký affiliate'}
                        visible={this.state.visibleFormRegister}
                        onOk={this.submitRegister}
                        onCancel={this.handleCancelFormRegister}
                        footer={<div className="text-center text-md-right"><Button key="back" onClick={this.handleCancelFormRegister}>
                            Đóng
                    </Button>
                            <Button key="submit" type="primary" loading={this.state.confirmLoading} onClick={this.submitRegister}>
                                Gửi
                    </Button></div>}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formRegister = evt}
                            initialValues={{
                                email: this.props.user.email,
                                phone: this.props.user.phone,
                                address: this.props.user.address
                            }}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Email là bắt buộc' }]}
                                label="Email"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[{ required: true, message: 'Số điện thoại là bắt buộc' }]}
                                label="Số điện thoại"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="address"
                                rules={[{ required: true, message: 'Địa chỉ là bắt buộc' }]}
                                label="Địa chỉ"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="link_facebook"
                                label="Link facebook"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="link_website"
                                label="Link website / blog"
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block block-rounded">
                                <div className="block-content">
                                    <Alert
                                        message="Thông báo"
                                        description={<p>Bạn chưa đăng ký tài khoản affiliate! vui lòng liên hệ tới <a href={this.props.service.link_support} target="_blank">chúng tôi</a> nếu có bất cứ thắc mắc gì!</p>}
                                        type="info"
                                    />
                                </div>
                                <div className="block-content">
                                    <button className="btn btn-m" onClick={() => this.setState({ visibleFormRegister: true })}>Đăng ký ngay</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {info_level_affilate}
                </div>
            )
        }
        if (this.state.affiliate.status == 0) {
            alert = <div className="row">
                <div className="col-md-12">
                    <div className="block block-rounded">
                        <div className="block-content">
                            <Alert
                                message="Thông báo"
                                description={<p>Tài khoản affiliate của bạn đang chờ xét duyệt! vui lòng liên hệ tới <a href={this.props.service.link_support} target="_blank">chúng tôi</a> nếu có bất cứ thắc mắc gì!</p>}
                                type="info"
                            />

                        </div>
                    </div>
                </div>
            </div>
        }
        if (this.state.affiliate.status == 2) {
            alert = <div className="row">
                <div className="col-md-12">
                    <div className="block block-rounded">
                        <div className="block-content">
                            <Alert
                                message="Thông báo"
                                description={<p>Tài khoản affiliate của bạn tạm thời bị khóa! vui lòng liên hệ tới <a href={this.props.service.link_support} target="_blank">chúng tôi</a> nếu có bất cứ thắc mắc gì!</p>}
                                type="warning"
                            />

                        </div>
                    </div>
                </div>
            </div>
        }
        return (
            <div className="content" style={{overflow: "hidden"}}>
                <Modal
                    title={'Yêu cầu rút tiền'}
                    visible={this.state.visibleFormWithDraw}
                    onOk={this.submitWithDraw}
                    onCancel={this.handleCancelWithDraw}
                    footer={[
                        <Button key="back" onClick={this.handleCancelWithDraw}>
                            Đóng
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.confirmLoading} onClick={this.submitWithDraw}>
                            Xác nhận
                        </Button>,
                    ]}
                >
                    <Form
                        name="basic"
                        onFinish={this.onFinish}
                        ref={(evt) => this.formH = evt}
                        initialValues={{
                            amount: 1000000
                        }}
                    >
                        <Form.Item
                            name="amount"
                            rules={[{ required: true, message: 'Số tiền bắt buộc' }]}
                            label={`Số tiền (${this.state.amountWithDraws} vnđ)`}
                            onChange={(e) => {
                                this.setState({
                                    amountWithDraws: new Intl.NumberFormat('vi-VN').format(e.target.value).replace(/\./g, ',')
                                })
                            }}
                            className="mb-5"
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        {/* <div className="text-danger mb-15">
                            {this.state.amountWithDraws} vnđ
                        </div> */}
                        <Form.Item
                            name="info_payment"
                            rules={[{ required: true, message: 'Nội dung chuyển khoản là bắt buộc' }]}
                            label="Nội dung chuyển khoản"
                        >
                            <Input.TextArea style={{ minHeight: "100px" }} />
                        </Form.Item>
                        <div>
                            Ví dụ nội dung chuyển khoản:
                            <p className="mb-0 pl-20">Ngân hàng: Vietcombank - Chi nhánh: ..</p>
                            <p className="mb-0 pl-20">Chủ TK: ..</p>
                            <p className="mb-0 pl-20">Số tài khoản: ..</p>
                        </div>
                    </Form>
                </Modal>
                {alert}
                {
                    this.state.affiliate.status != 0 ?
                        <div className="row" style={{ marginBottom: "24px" }}>
                            <div className="col-md-4">
                                <div className="block block-rounded mb-0" style={{ height: "100%", background: "#f29e4f" }}>
                                    <div className="block-content d-flex" style={{ flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                                        <h4 className="text-white">Tài khoản affiliate level {this.state.affiliate.level.level}</h4>
                                        <h3 className="text-white">{<NumberFormat value={this.state.affiliate.wallet} displayType={'text'} thousandSeparator={true} />} đ</h3>
                                        {
                                            this.state.affiliate.status == 1 ?
                                                <div className="d-flex">
                                                    <button className="btn btn-m mr-10" onClick={this.withdraws}>Rút tiền</button>
                                                    <Link className="btn btn-m" to="/affiliate/withdraws">Lịch sử rút</Link>
                                                </div> : <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="block block-rounded mb-0" style={{ height: "100%", background: "#4080ff" }}>
                                    <div className="block-content text-center">
                                        <h4 className="text-white">Hoa hồng thanh toán lần đầu</h4>
                                        <h3 className="text-white">{this.state.affiliate.level.commission}%</h3>
                                        <p className="mb-0 text-white">Hoa hồng gia hạn {this.state.affiliate.level.commission_extension}%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="block block-rounded mb-0" style={{ height: "100%" }}>
                                    <div className="block-content">
                                        <h4>Link đăng ký</h4>
                                        <div className="d-flex" style={{ marginBottom: "20px" }}>
                                            <div style={{ width: "100%" }}>
                                                <input className="form-control" disabled value={`${dimens.full_domain}?ref=${this.state.affiliate.user_id}`} style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }} />
                                                <input className="opacity-0" id="link-ref" value={`${dimens.full_domain}?ref=${this.state.affiliate.user_id}`} style={{ position: "fixed", top: "-100px" }} />
                                            </div>
                                            {
                                                this.state.affiliate.status == 1 ?
                                                    <div>
                                                        <button onClick={this.copyLinkRef} className="btn btn-m" style={{ width: "max-content", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}>Sao chép</button>
                                                    </div> : null
                                            }
                                        </div>
                                        <ul className="list-select-menu">
                                            <li><i className="si si-check"></i> Copy link này gửi cho những khách hàng khác và hướng dẫn họ đăng ký</li>
                                            <li><i className="si si-check"></i> Cookie 30 ngày và không bị ghi đè bởi link của người giới thiệu sau</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }
                {
                    this.state.affiliate.status == 1 ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block block-rounded">
                                    <div className="block-header">
                                        <h3 className="block-title">Thống kê</h3>
                                        <div>
                                            <RangePicker
                                                locale={locale}
                                                defaultValue={[moment(this.filter_statistic.start), moment(this.filter_statistic.end)]}
                                                format={'DD-MM-YYYY'}
                                                ranges={{
                                                    // 'Hôm nay': [moment(), moment()],
                                                    'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                                    'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                                }}
                                                onChange={(moments, dateString) => {
                                                    let time_start = moments[0].startOf('day').unix() * 1000;
                                                    let time_end = moments[1].endOf('day').unix() * 1000;
                                                    this.filter_statistic = {
                                                        start: time_start,
                                                        end: time_end
                                                    }
                                                    this.getStatistic();
                                                }}
                                                format="DD/MM/YYYY"
                                                allowClear={false}

                                            />

                                        </div>
                                    </div>
                                    <div className="block-content pt-0">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-vcenter">
                                                <thead>
                                                    <tr>
                                                        <th>Tổng giới thiệu</th>
                                                        <th>Số giao dịch</th>
                                                        <th>Hỏa hồng</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.state.statistic ? this.state.statistic.total_user : 0}</td>
                                                        <td>{this.state.statistic ? this.state.statistic.total_transaction : 0}</td>
                                                        <td><NumberFormat value={this.state.statistic ? this.state.statistic.amount : 0} displayType={'text'} thousandSeparator={true} />đ</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="block block-rounded">
                                    <div className="block-header">
                                        <h3 className="block-title">Biểu đồ</h3>
                                    </div>
                                    <div className="block-content pt-0" id="block-myChart">
                                        <canvas id="myChart" width="400" height="120"></canvas>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="block block-rounded">
                                    <div className="block-header">
                                        <h3 className="block-title">Người dùng nạp tiền</h3>
                                        {
                                            this.state.referral.total > 0 ?
                                                <div className="d-flex align-items-center">
                                                    <Link to="/affiliate/transaction" style={{ textDecoration: "underline" }}>Chi tiết giao dịch <i className="si si-arrow-right-circle ml-5"></i></Link>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="block-content">
                                        <div className="table-responsive">
                                            {/* <LoadSpinner show={this.state.loading} /> */}
                                            <table className="table table-striped table-vcenter table-white-space">
                                                <thead>
                                                    <tr>
                                                        <th>Tên người dùng</th>
                                                        <th>Email</th>
                                                        <th>Thời gian nạp tiền mới nhất</th>
                                                        <th>Số lần thanh toán</th>
                                                        <th>Tổng tiền</th>
                                                        <th>Ngày giới thiệu</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.referral.data.length > 0 ? this.state.referral.data.map((item, i) => {
                                                            return <tr key={i}>
                                                                <td>{item.display_name}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.last_time_payment ? moment(item.last_time_payment).format('HH:mm DD/MM/YYYY') : ''}</td>
                                                                <td>{item.count_payment}</td>
                                                                <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} />đ</td>
                                                                <td>{moment(item.referral_time).format('HH:mm DD/MM/YYYY')}</td>
                                                            </tr>
                                                        }) : <tr><td colSpan={11} className="text-center">Chưa có dữ liệu</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {
                                                this.state.referral.total < this.limit && this.page == 1 ? null :
                                                    <Pagination
                                                        total={this.state.referral.total}
                                                        pageSize={this.limit}
                                                        current={this.page}
                                                        onShowSizeChange={(current, size) => {
                                                            this.limit = size;
                                                            this.page = current;
                                                            this.getlistReferral();
                                                        }}
                                                        onChange={(current) => {
                                                            this.page = current;
                                                            this.getlistReferral();
                                                        }}
                                                    />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }
                {info_level_affilate}

            </div>
        )
    }
}
