import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AccountMetaData, dimens, versionCompare} from '../../../config/app';

export default class SidebarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    postLogout = async () => {
        AccountMetaData.logOut();
    }
    checkExtApp = async (e) => {
        e.preventDefault();
        try {
            if(this.props.user.packages == 0){
                this.props.notification({
                    content: 'Bạn chưa có gói cước nào được kích hoạt, vui lòng thử lại!',
                    title: 'Sử dụng tiện ích',
                    type: 'warning'
                })
                return
            } else {
                if (window.extApp && window.extApp.isConnected) {
                    if(!window.extApp.manifest || versionCompare(this.props.service.ver_ext, window.extApp.manifest.version) == 1){
                        throw(true);
                    } else{
                        let result = await window.extApp.request({ method: 'start_url', domain: dimens['domainSave']});
                        window.open(result.url);
                    }
                } else {
                    throw (true);
                }
            }
        } catch (err) {
            this.props.notification({
                content: `Bạn chưa cài tiện ích hoặc version tiện ích đã cũ, vui lòng cài version lớn hơn hoặc bằng ${this.props.service.ver_ext || ''}`,
                title: 'Sử dụng tiện ích',
                type: 'warning'
            })
            this.props.history.push('/guides#cai-tien-ich')
        }
    }
    componentDidMount = () => {
        document.querySelectorAll('[data-toggle="sidebar"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                let action = this.getAttribute('data-action');
                if (action == 'sidebar_show') {
                    document.querySelector('#page-container').classList.add('sidebar-o-xs');
                } else {
                    console.log(action);
                    document.querySelector('#page-container').classList.remove('sidebar-o-xs');
                }
            })
        })

        document.querySelectorAll('#sidebar .nav-main li').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                document.querySelector('#page-container').classList.remove('sidebar-o-xs');
            })
        })
        document.querySelectorAll('.nav-submenu[data-toggle="nav-submenu"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                let list_class = e.target.parentNode.getAttribute('class');
                if (list_class.indexOf('open') > -1) {
                    e.target.parentNode.classList.remove('open');
                } else {
                    e.target.parentNode.classList.add('open');
                }
            })
        })
    }
    render() {
        let pathname = this.props.location ? this.props.location.pathname : '';
        let hostname = window.location.hostname;
        let menu_admin = [];
        let path_logo = "/logo.png";
        let text_logo = null;

        if (this.props.user.is_owner) {
            menu_admin.push(<li className="nav-main-heading pt-10 border-t" key={'header-menu-admin'}><span>Quản trị</span></li>);
            menu_admin.push(<li key="header-menu-admin-1">
                <Link to="/admin/transaction" className={pathname.indexOf('/admin/transaction') > -1 ? 'active' : ''}>
                    <i className="si si-notebook"></i><span className="sidebar-mini-hide">Đơn hàng</span>
                </Link>
            </li>)
            menu_admin.push(<li className={pathname.indexOf('/admin/affiliate') == 0 ? 'open' : ''}>
            <a className="nav-submenu" data-toggle="nav-submenu" href="#"><i className="si si-puzzle"></i><span className="sidebar-mini-hide">Affiliate</span></a>
            <ul>
                <li>
                    <Link to="/admin/affiliate/user" className={pathname.indexOf('/admin/affiliate/user') > -1 ? 'active' : ''}>Người dùng</Link>
                </li>
                <li>
                    <Link to="/admin/affiliate/withdraws" className={pathname.indexOf('/admin/affiliate/withdraws') > -1 ? 'active' : ''}>Yêu cầu rút tiền</Link>
                </li>
            </ul>
        </li>)
            if(this.props.user.is_root){
                menu_admin.push(<li>
                <Link to="/admin/user" className={pathname.indexOf('/admin/user') > -1 ? 'active' : ''}>
                    <i className="si si-users"></i><span className="sidebar-mini-hide">Người dùng</span>
                </Link>
            </li>)
                menu_admin.push(<li>
                    <Link to="/admin/service" className={pathname.indexOf('/admin/service') > -1 ? 'active' : ''}>
                        <i className="si si-location-pin"></i><span className="sidebar-mini-hide">Domain</span>
                    </Link>
                </li>)
            }
        }
        return (
            <nav id="sidebar">
                <div className="sidebar-content">
                    <div className="content-header content-header-fullrow px-15">
                        <div className="content-header-section text-center align-parent sidebar-mini-hidden">
                            <div className="content-header-item logo">
                                {
                                    path_logo ? <img src={path_logo} style={{ width: "100px", height: "100%", objectFit: "contain"}} /> : null
                                }
                                { text_logo ? <h2 style={{color: "#fff"}}>{text_logo}</h2> : null}
                            </div>
                            <button type="button" className="btn btn-circle btn-dual-secondary align-v-r d-sm-none" data-toggle="sidebar" data-action="sidebar_hide">
                                <i className="fa fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div className="content-side content-side-full">
                        <ul className="nav-main">
                            <li>
                                <a onClick={this.checkExtApp} style={{ background: "#f29e4f", color: "#fff", borderRadius: "5px", paddingLeft: "18px" }}>
                                    <i className="si si-arrow-right-circle" style={{ color: "#fff", left: "unset", right: "15px" }}></i><span className="sidebar-mini-hide">Sử dụng ngay</span>
                                </a>
                            </li>
                            <li>
                                <Link to="/accounts" className={pathname.indexOf('/accounts') > -1 || pathname === '/' ? 'active' : ''}>
                                    <i className="si si-user"></i><span className="sidebar-mini-hide">Tài khoản</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/plans" className={pathname.indexOf('/plans') > -1 ? 'active' : ''}>
                                    <i className="si si-cloud-upload"></i><span className="sidebar-mini-hide">Gói cước</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/affiliate" className={pathname.indexOf('/affiliate') == 0 ? 'active' : ''}>
                                    <i className="si si-share"></i><span className="sidebar-mini-hide">Affiliate</span>
                                </Link>
                            </li>
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Tiện ích</span>
                            </li>
                            <li>
                                <Link to="/auto-comment" className={pathname.indexOf('/auto-comment') == 0 ? 'active' : ''}>
                                    <i class="fal fa-comments"></i><span className="sidebar-mini-hide">Auto comment</span>
                                </Link>
                            </li>
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Hỗ trợ</span>
                            </li>
                            {
                                this.props.service.site_scan ?
                                <li>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = this.props.service.site_scan;

                                    }} target="_blank">
                                        <i className="si si-shuffle"></i><span className="sidebar-mini-hide">Scan UID</span>
                                    </a>
                                </li> : null
                            }
                            {/* <li>
                                <Link to="/guides" className={pathname.indexOf('/guides') > -1 ? 'active' : ''}>
                                    <i className="si si-doc"></i><span className="sidebar-mini-hide">Hướng dẫn</span>
                                </Link>
                            </li> */}
                            {
                                this.props.user.packages > 0 ?
                                    <li>
                                        <a href={process.env.REACT_APP_LINK_DOWNLOAD_EXT_SPAM} target="_blank">
                                            <i className="si si-cloud-download"></i><span className="sidebar-mini-hide">Tải tiện ích</span>
                                        </a>
                                    </li>
                                    : null
                            }
                            {
                                this.props.service.link_support ?
                                    <li>
                                        <a href={this.props.service.link_support} target="_blank">
                                            <i className="si si-earphones-alt"></i><span className="sidebar-mini-hide">Hỗ trợ</span>
                                        </a>
                                    </li>
                                    : null
                            }
                            <li>
                                <Link to="/guides" className={pathname.indexOf('/guides') == 0 ? 'active' : ''}>
                                    <i className="si si-docs"></i><span className="sidebar-mini-hide">Hướng dẫn</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/dieu-khoan" className={pathname.indexOf('/dieu-khoan') == 0 ? 'active' : ''}>
                                    <i className="si si-info"></i><span className="sidebar-mini-hide">Điều khoản & sử dụng</span>
                                </Link>
                            </li>
                            {menu_admin}
                            <li className="nav-main-heading pt-0 border-t"></li>
                            <li>
                                <a onClick={this.postLogout}>
                                    <i className="si si-logout"></i><span className="sidebar-mini-hide">Đăng xuất</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}
