import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import { fetchData } from '../../../lib/apis';
import { AccountMetaData, dimens } from '../../../config/app';
import moment from 'moment';

export default class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            getPackage: false
        };
    }
    componentWillMount = async () => {
        this.getPackage();
        // this.syncPages();
    }
    syncPages = async () => {
        try {
            let response = await fetchData({
                url: 'api/v1/pages/sync',
                method: 'get'
            })
        } catch (err) {

        }
    }
    getPackage = async () => {
        try {
            let response = await fetchData({
                url: 'api/v1/package/list',
                method: 'get'
            })
            this.setState({
                packages: response.data,
                getPackage: true
            })
        } catch (err) {

        }
    }

    render() {
        return (
            <div className="content">
                <div className="row row flex-column-reverse flex-md-row">
                    <div className="col-md-6">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Thông tin cá nhân</h3>
                            </div>
                            <div className="block-content block-content-full text-center">
                                <img className="img-avatar img-avatar80 img-avatar-thumb" src={this.props.getPicture({ id: this.props.user.uid, token: this.props.user.access_token })} alt="" style={{ width: "80px", height: "80px" }} />
                            </div>
                            <div className="block-content">
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Họ và tên</label>
                                        <input type="text" className="form-control input-text" name="display_name" value={this.props.user.display_name} disabled />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Facebook id</label>
                                        <input type="text" className="form-control input-text" name="uid" value={this.props.user.uid} disabled />
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Auth token</label>
                                        <input type="text" className="form-control input-text" name="token" value={dimens['token']} onClick={(e) => e.target.select()} />
                                    </div>
                                </div> */}
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Email</label>
                                        <input type="text" className="form-control input-text" name="display_name" value={this.props.user.email} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Số điện thoại</label>
                                        <input type="text" className="form-control input-text" name="phone" value={this.props.user.phone} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Địa chỉ</label>
                                        <input type="text" className="form-control input-text" name="address" value={this.props.user.address} />
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <div className="col-12 text-center">
                                        <button className="btn btn-m">Lưu thông tin</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="block block-rounded">
                            <div className="block-header pb-0">
                                <h3 className="block-title">Gói cước đang sử dụng</h3>
                            </div>
                            <div className="block-content">
                                <div style={{ background: "#eff2f5", borderRadius: "5px"}}>
                                    {
                                        this.state.packages.length > 0 ?
                                        <div style={{maxHeight: "300px", overflow: "auto"}}>
                                            <table className="table table-borderless table-packages">
                                            {
                                                this.state.packages.map((item, i) => {
                                                    let time_end = item.time_expired - Date.now();
                                                    let num_month = moment(moment(item.time_expired)).diff(moment(moment(Date.now())), 'month', true);
                                                    if(time_end > (60 * 1000 * 60 * 24 * 29)) {
                                                        num_month = moment(moment(item.time_expired)).diff(moment(moment(Date.now())), 'month', true);
                                                        num_month = `${Math.round(num_month)} tháng`;
                                                    } else if(time_end > (60 * 1000 * 60 * 24)){
                                                        num_month = moment(moment(item.time_expired)).diff(moment(moment(Date.now())), 'days', true);
                                                        num_month = `${Math.round(num_month)} ngày`;
                                                    } else if(time_end > (60 * 1000 * 60)){
                                                        num_month = moment(moment(item.time_expired)).diff(moment(moment(Date.now())), 'hours', true);
                                                        num_month = `${Math.round(num_month)} giờ`;
                                                    } else {
                                                        num_month = moment(moment(item.time_expired)).diff(moment(moment(Date.now())), 'minutes', true);
                                                        num_month = `${Math.round(num_month)} phút`;
                                                    }
                                                    return <tr>
                                                        <td>
                                                            <p className="mb-2"><strong>{item.title}</strong></p>
                                                            {
                                                                item.number_page ?
                                                                    <p className="mb-2">
                                                                        Kích hoạt:
                                                                    <span className="text-success"> {item.number_page_active}</span>
                                                                        {item.number_page ? '/' : ''}
                                                                        <span>{item.number_page ? item.number_page : ''}</span>
                                                                    </p> : item.number_page == 0 ? <p className="mb-2">Số lượng: Không giới hạn.</p> : ''
                                                            }
                                                            {
                                                                item.max_number_message ?
                                                                    <p className="mb-2">
                                                                        Tin nhắn:
                                                                    <span className="text-success"> {item.number_message_active}</span>
                                                                    /
                                                                    <span>{item.max_number_message}</span>
                                                                    </p> : null
                                                            }
                                                            {
                                                                item.number_package ?
                                                                    <p className="mb-2">
                                                                        Gói cước đang dùng: {item.number_package}
                                                                    </p> : null
                                                            }
                                                            <p className="mb-2">Ngày hết Hạn: {moment(item.time_expired).format('YYYY/MM/DD HH:mm')} (<span className="text-danger"> còn {num_month}</span> )</p>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </table>
                                        </div> : null
                                    }
                                    <table className="table table-borderless border-top">
                                        { this.state.packages.length > 0 ? 
                                            <tr>
                                            <td colSpan={11} className="text-center">
                                                <button className="btn btn-m" onClick={() => {
                                                    this.props.history.push('/plans');
                                                }}>Nâng cấp gói cước</button>
                                            </td>
                                        </tr>   
                                            : this.state.getPackage ? <tr>
                                                <td colSpan={11} className="text-center">
                                                    <p className="mb-2"><strong>Bạn chưa có gói cước nào</strong></p>
                                                    <button className="btn btn-m" onClick={() => {
                                                        this.props.history.push('/plans');
                                                    }}>Mua ngay</button>
                                                </td>
                                            </tr>  : null
                                            }
                                    </table>
                                </div>
                                {
                                        this.state.getPackage && this.state.packages.length == 0 ?
                                        <div className="mt-10">
                                            <h4 className="block-title">Một số tính năng nổi bật</h4>
                                            <table className="table table-bordered table-vcenter mt-10">
                                                <tr>
                                                    <td><strong>Gói remarketing</strong></td>
                                                    <td>
                                                    - Re-inbox đến tất cả khách hàng trong fanpage <br></br>
                                                    - Không giới hạn tin nhắn<br></br>
                                                    - Gói vip không giới hạn tài khoản
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Tự động comment vào page, group</strong></td>
                                                    <td>
                                                        - Bình luận đến tất cả uid mục tiêu<br></br>
                                                        - Không giới hạn comment<br></br>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        : null
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
