const chrome = window.chrome

class ExtError extends Error {
  constructor(status, error = '') {
    super(error)
    this.status = status
    this.error = error
    this.message = `ExtError ${status}: ${error.response ? error.response.message : error}`
    this.response = error.response
  }
}

class TimeoutError extends Error { }
class NotInstalledError extends Error { }

const generate_req_id = (() => {
  let req_id = Date.now()
  return () => req_id++
})()

class ExtfbaseConnector {
  _currend_id = ''
  ext_id = process.env.REACT_APP_KEY_EXT

  isStopped = false
  isConnected = false

  port = null
  info = {}

  _check_working_id = () =>
    new Promise((resolve, reject) => {
      setTimeout(
        () => reject('Cant find any working extension'),
        500,
      )
      chrome.runtime.sendMessage(
        this.ext_id,
        { method: 'ping' },
        null,
        ({ status, pong, manifest} = {}) =>
          status === 'ok' && pong && resolve({
            port: this.ext_id,
            manifest: manifest
          }),
      )

    })

  _try_connect_port = () =>
    new Promise((resolve, reject) => {
      const port = chrome.runtime.connect(this._currend_id)

      port.onDisconnect.addListener(() => resolve(null))

      setTimeout(() => {
        resolve(port)
      }, 100)
    })

  init = async () => {
    if (!chrome.runtime) {
      this.isConnected = false
      return
    }
    try {
      let res = await this._check_working_id();
      this._currend_id = res.port;
      this.manifest = res.manifest;

      this.port = await this._try_connect_port()

      this.isConnected = true;

    } catch (err) {
      if (err instanceof TimeoutError) {
        this.isConnected = false
        return
      }

      throw err
    }
  }

  start = () => (this.isStopped = false)
  kill = () => (this.isStopped = true)

  request = (data, wake = false) =>
    new Promise((resolve, reject) => {
      const wasWorking = !this.isStopped

      if (wake && !wasWorking) this.start()

      if (this.isStopped) return reject({
        status_code: 501,
        message: 'Request was skipped'
      })
      if (!this.isConnected) return reject({
        status_code: 0,
        message: 'Not connection'
      })

      // setTimeout(() => reject('Request timeout'), 10000)

      const req_id = generate_req_id()

      const onResponse = message => {
        if (!message) return reject({
          status_code: 502,
          message: 'Not Message'
        })

        const { status, error } = message

        if (req_id !== message.req_id) { return }

        // console.log('request', message)

        if (status !== 'ok') {
          reject(error)
        } else {
          resolve(message)
        }

        this.port && this.port.onMessage.removeListener(onResponse)
      }

      // console.log(`send_message`, this._currend_id, data)

      if (!this.port) {
        chrome.runtime.sendMessage(this._currend_id, data, null, onResponse)
      } else {
        this.port.onMessage.addListener(onResponse)
        try {
          this.port.postMessage({ req_id, ...data })
        } catch (err) {
          reject({
            status_code: 1,
            error: err
          })
        }
      }

      if (wake && !wasWorking) this.kill()
    })
}

const extApp = new ExtfbaseConnector()

window.extApp = extApp

export default extApp
export { ExtfbaseConnector, ExtError, NotInstalledError, TimeoutError }
