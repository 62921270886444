const settings = {}

export var dimens = {
    deviceWidth: window.innerWidth
}

export const AccountMetaData = {
    token: (getCookie("df_t")) ? getToken(getCookie("df_t")) : '',
    logOut : () => {
        setCookie('df_t', '', 0);
        setCookie('c_t', '', 0);
        window.location.href = `${process.env.REACT_APP_LOGIN}/logout?urlRedirect=${window.location.origin}/login`;
        return true;
    },
    logIn: () => {
        window.location.href = `${process.env.REACT_APP_LOGIN}/login?urlRedirect=${window.location.origin}/login`;
    },
    getToken: () => {
        try {
            return atob(getCookie("df_t"));
        } catch {
            return '';
        }
    },
    getCompany: () => {
        try {
            let company = getCookie("c_t");
            return company ? JSON.parse(company) : '';
        } catch {
            return '';
        }
    },
    setCookie: (value, days) => {
        var name = "df_t";
        var expires = "";
        if (days) {
        var date = new Date();
            date.setMonth(date.getMonth() + 1);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + ";domain=; path=/";
    },
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    checkIssetLogin: () => {
        if( !getCookie('df_t')  ) {
            return false;
        }
        try{
          dimens['df_t'] = atob(getCookie("df_t"));
        } catch(err){
            return false
        }
        return true;
    }
}

export const formatNumber = (nStr) => {
    nStr+='';let x=nStr.split(',');let x1=x[0];let x2=x.length>1?','+x[1]:'';let rgx=/(\d+)(\d{3})/;while(rgx.test(x1)){x1=x1.replace(rgx,'$1'+'.' + '$2');}return x1+x2;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
        date.setMonth(date.getMonth() + 1);
        expires = "; expires=" + date.toUTCString();
    }
    // document.cookie = name + "=" + (value || "") + expires + ";domain=.muabanhay.com; path=/";
    document.cookie = name + "=" + (value || "") + expires + ";domain=; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function checkIssetLogin(){
  if( !getCookie('df_t')  ) {
      return false;
  }
  try{
    dimens['df_t'] = atob(getCookie("df_t"));
  } catch(err){
      return false
  }
  return true;
}
export function getToken(cookie){
    try {
        return atob(cookie);
    } catch {
        return '';
    }
}
export default settings