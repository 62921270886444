import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import { AccountMetaData, dimens} from '../../../config/app';

export default class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {
        
    }
    render() {
        // console.log(this.props);
        let hostname = window.location.hostname;
        return (
            <header id="page-header">
                <div className="content-header">
                    <div className="content-header-section">
                        <button type="button" class="btn btn-circle btn-dual-secondary d-sm-none" data-toggle="sidebar" data-action="sidebar_show">
                            <i class="fa fa-navicon"></i>
                        </button>
                    </div>
                    <div className="content-header-section">
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-rounded" id="page-header-user-dropdown" style={{display: "flex", alignItems: "center", paddingRight: "0px"}}>
                                {/* <i className="fa fa-user d-sm-none"></i> */}
                                <Popover content={
                                <div className="" style={{minWidth: "150px"}}>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = 'https://'+dimens.domainSave;
                                        if(window.location.href.indexOf('forisoftware') > -1){
                                            window.location.href = 'https://autochat.forisoftware.com';
                                        }
                                    }} className="dropdown-item"><i className="si si-home mr-5"></i> Trang chủ</a>
                                    <Link className="dropdown-item" to="/accounts"><i className="si si-user mr-5"></i> Tài khoản</Link>
                                    <Link className="dropdown-item" to="/plans"><i className="si si-cloud-upload mr-5"></i> Gói cước</Link>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={() => AccountMetaData.logOut()}><i className="si si-logout mr-5"></i> Đăng xuất</a>
                                </div>
                                } trigger="click" placement="bottom">
                                    <a className="img-link mr-1" href="#">
                                        <img className="img-avatar" src={this.props.user.picture} alt="" style={{width: "35px", height: "35px"}}/>
                                    </a>
                                    <i className="fa fa-angle-down mr-10"></i>
                                </Popover>
                                <div className="header-acc-name shadow">{this.props.user.display_name}</div>
                            </button>
                        </div>
                        <div className="btn-group" role="group">
                            {/* <button type="button" className="btn btn-rounded" id="page-header-notifications" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-bell"></i>
                                <span className="badge badge-primary badge-pill">5</span>
                            </button> */}
                            <div className="dropdown-menu dropdown-menu-right min-width-300" aria-labelledby="page-header-notifications">
                                <h5 className="h6 text-center py-10 mb-0 border-b text-uppercase">Notifications</h5>
                                <ul className="list-unstyled my-20">
                                    <li>
                                        <a className="text-body-color-dark media mb-15" href="javascript:void(0)">
                                            <div className="ml-5 mr-15">
                                                <i className="fa fa-fw fa-check text-success"></i>
                                            </div>
                                            <div className="media-body pr-10">
                                                <p className="mb-0">You’ve upgraded to a VIP account successfully!</p>
                                                <div className="text-muted font-size-sm font-italic">15 min ago</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-body-color-dark media mb-15" href="javascript:void(0)">
                                            <div className="ml-5 mr-15">
                                                <i className="fa fa-fw fa-exclamation-triangle text-warning"></i>
                                            </div>
                                            <div className="media-body pr-10">
                                                <p className="mb-0">Please check your payment info since we can’t validate them!</p>
                                                <div className="text-muted font-size-sm font-italic">50 min ago</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-body-color-dark media mb-15" href="javascript:void(0)">
                                            <div className="ml-5 mr-15">
                                                <i className="fa fa-fw fa-times text-danger"></i>
                                            </div>
                                            <div className="media-body pr-10">
                                                <p className="mb-0">Web server stopped responding and it was automatically restarted!</p>
                                                <div className="text-muted font-size-sm font-italic">4 hours ago</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-body-color-dark media mb-15" href="javascript:void(0)">
                                            <div className="ml-5 mr-15">
                                                <i className="fa fa-fw fa-exclamation-triangle text-warning"></i>
                                            </div>
                                            <div className="media-body pr-10">
                                                <p className="mb-0">Please consider upgrading your plan. You are running out of space.</p>
                                                <div className="text-muted font-size-sm font-italic">16 hours ago</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-body-color-dark media mb-15" href="javascript:void(0)">
                                            <div className="ml-5 mr-15">
                                                <i className="fa fa-fw fa-plus text-primary"></i>
                                            </div>
                                            <div className="media-body pr-10">
                                                <p className="mb-0">New purchases! +$250</p>
                                                <div className="text-muted font-size-sm font-italic">1 day ago</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item text-center mb-0" href="javascript:void(0)">
                                    <i className="fa fa-flag mr-5"></i> View All
                                </a>
                            </div>
                        </div>
                        {/* <button type="button" className="btn btn-circle btn-dual-secondary" data-toggle="layout" data-action="side_overlay_toggle">
                            <i className="fa fa-tasks"></i>
                        </button> */}
                    </div>
                </div>

                <div id="page-header-search" className="overlay-header">
                    <div className="content-header content-header-fullrow">
                        <form action="be_pages_generic_search.html" method="post">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <button type="button" className="btn btn-secondary" data-toggle="layout" data-action="header_search_off">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                                <input type="text" className="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input" />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-secondary">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="page-header-loader" className="overlay-header bg-primary">
                    <div className="content-header content-header-fullrow text-center">
                        <div className="content-header-item">
                            <i className="fa fa-sun-o fa-spin text-white"></i>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
