import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip} from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import { LoadSpinner } from '../../../widgets';

export default class TransactionAffiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { data: [], total: 0},
            valuesFilter: {}
        };
        this.page = 1;
        this.limit = 20;
    }
    componentWillMount = async () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let query = `limit=${this.limit}&page=${this.page}`;
            for (let [key, value] of Object.entries(this.state.valuesFilter)) {
                query += `&${key}=${value}`;
            }
            let res = await fetchData({
                url: `api/v1/affiliate/transaction?${query}`
            });

            this.setState({ data: res,loading: false});
        } catch (err) {
            this.setState({ loading: false });
        }
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 300)
    }
    handleFilter = (e) => {
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter }, () => { this.getListData('reset') });
    }
    render() {
        return (
            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title d-flex align-items-center">
                                    <Tooltip
                                        title="Quay lại"
                                    >
                                        <Link to="/affiliate" className="si si-arrow-left-circle mr-10 text-black"></Link>
                                    </Tooltip>
                                    Chi tiết giao dịch
                                </h3>
                            </div>
                            <div className="block-content">
                                <div className="table-responsive">
                                    <LoadSpinner show={this.state.loading} />
                                    <table className="table table-striped table-vcenter table-white-space">
                                        <thead>
                                            <tr>
                                                <th>Code</th>
                                                <th>Tên người dùng</th>
                                                <th>Email</th>
                                                {/* <th>Gói cước</th> */}
                                                <th>Ngày nạp tiền</th>
                                                <th>Số tiền</th>
                                                <th className="text-center">Hoa hồng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.data.length >  0 ? this.state.data.data.map((item, i) => {
                                                    return <tr key={i}>
                                                        <td>{item.code}</td>
                                                        <td>{item.display_name}</td>
                                                        <td>{item.email}</td>
                                                        {/* <td></td> */}
                                                        <td>{moment(item.time_payment).format('HH:mm DD/MM/YYYY')}</td>
                                                        <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /> đ</td>
                                                        <td className="text-center">
                                                            {/* <NumberFormat value={ Math.floor((item.amount/100) * item.affiliate.commission) } displayType={'text'} thousandSeparator={true} /> */}
                                                            {item.affiliate.commission} %
                                                        </td>
                                                    </tr>
                                                }) : <tr><td colSpan={11}>Chưa có dữ liệu</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="block-content d-flex" style={{justifyContent: "flex-end"}}>
                                    <div></div>
                                    <Pagination
                                        total={this.state.data.total}
                                        pageSize={this.limit}
                                        current={this.page}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
